/**
* @name formsController
* @desc forms Controller.
**/
(function() {
  "use strict";

  angular
      .module("CareGuard")
      .controller("formsController", formsController);

  /** Inject services into controller. */
  formsController.$inject = ['$scope', '$location', '$state', '$timeout', 'formsService', 'memberService', 'memberFlagsService', 'lookupService', 'accountService', 'memberID','utilService'];

  /**
  * @name formsController.
  * @desc forms Controller.
  * @param {object} searchService - search service.
  */
  function formsController($scope, $location, $state, $timeout, formsService, memberService, memberFlagsService, lookupService, accountService, memberID, utilService) {
    
    // variables
    var vm = this;
    var today = undefined;
    //var iframe = undefined;

    vm.lookupdata = undefined;
    vm.member = undefined;
    vm.memberflags = undefined;

    vm.forms = undefined;
    vm.agreements = undefined;
    vm.quotes = undefined;
    vm.addendums = undefined;

    vm.selectedForm = undefined;

    //functions
    vm.selectForm = selectForm;


    init();

    /* 
      * @name init
      * @desc initialize module
      */
    function init() {
      //iframe = document.createElement("iframe");

      //get formLetters (by type) -uses the same sp as legacy
      formsService.getFormLetters('Forms').then(function (result) {
        vm.forms = result.Data;

      });
      formsService.getFormLetters('Agreement').then(function (result) {
        vm.agreements = result.Data;

      });
      formsService.getFormLetters('Quote').then(function (result) {
        vm.quotes = result.Data;

      });
      formsService.getFormLetters('Addendum').then(function (result) {
        vm.addendums = result.Data;

      });


      //get todays date as ReceivedDate, for creating claims
      today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();

      if (dd < 10) {
        dd = '0' + dd
      }

      if (mm < 10) {
        mm = '0' + mm
      }
      today = mm + '-' + dd + '-' + yyyy;

      //check if memberID was passed in; if so, get member
      if (memberID > 0) {
        memberService.getById(memberID).then(function (result) {
          vm.member = result.Data;

          
        });

        memberFlagsService.getMemberFlags(memberID).then(function (resultset) {
          vm.memberflags = resultset.Data;

        });
      }


    };
    //end of init

    //forms

    function selectForm(formType, formID) {
      if (memberID > 0) {
        if (formType == "Forms") {
          vm.selectedForm = Enumerable.from(vm.forms).where(function (x) { return x.FormID == formID }).toArray();
        }
        else if (formType == 'Agreement') {
          vm.selectedForm = Enumerable.from(vm.agreements).where(function (x) { return x.FormID == formID }).toArray();
        }
        else if (formType == 'Quote') {
          vm.selectedForm = Enumerable.from(vm.quotes).where(function (x) { return x.FormID == formID }).toArray();
        }
        else if (formType == 'Addendum') {
          vm.selectedForm = Enumerable.from(vm.addendums).where(function (x) { return x.FormID == formID }).toArray();
        }
        else {
          alert("Unknown form type!");
        }
        
        //console.log(vm.selectedForm[0].FormName);
        //console.log("/api/forms/selectForm/" + vm.member.MemberID + '/' + vm.member.MemberNumber + '/' + vm.selectedForm[0].FormID);
        //iframe.src = "/api/forms/selectForm/" + vm.member.MemberID + '/' + vm.member.MemberNumber + '/' + vm.selectedForm[0].FormID

        ////console.log(iframe.src);
        //// This makes the IFRAME invisible to the user.
        //iframe.style.display = "none";

        //// Add the IFRAME to the page.  This will trigger
        ////   a request to GenerateFile now.
        //document.body.appendChild(iframe);

        ////formsService.selectForm(vm.member.MemberID, vm.member.MemberNumber, vm.selectedForm[0].FormID).then(function (resultset) {
        ////  console.log(resultset.Data);

        ////});
        var exportOptions = {
          method: 'GET',
          url: "/api/forms/selectForm/" + vm.member.MemberID + '/' + vm.member.MemberNumber + '/' + vm.selectedForm[0].FormID
          };
        utilService.download(exportOptions);
      }
      else {
        alert("No member selected!");
      }

    };



  };  
})();