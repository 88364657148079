angular
    .module('CareGuard')
    .controller('bankingController', bankingController);

bankingController.$inject = [
    'bankingService',
    'lookupService',
    'accountService',
    'memberService',
    'memberFlagsService',
    'memberID',
    'bankEnum',
    'addressType',
    '$toastr',
    '$q'];

function bankingController(
    bankingService,
    lookupService,
    accountService,
    memberService,
    memberFlagsService,
    memberID,
    bankEnum,
    addressType,
    $toastr,
    $q) {

    var vm = this;

    vm.isInFinanceRole = false;
    vm.isInPaymentModificationRole = false;
    vm.isInHoldModificationRole = false;

    vm.member = {};
    vm.memberflags = [];

    vm.lookupdata = {};
    vm.activeBanks = {};
    vm.allBanks = {};
    vm.memberBanks = { isEdit: false };
    vm.isNewMemberBank = false;
    vm.holdAmounts = [];
    vm.isNewHoldAmount = false;
    vm.memberTransactionResponses = { isEdit: false }; //legacy "transaction"
    vm.memberAccumulationResponses = { isEdit: false };
    vm.memberStatement = {};
    vm.memberStatementDetails = []; //actual transactions, but represented as the member statement
    vm.memberAddresses = {};

    let dataReferences = {
        memberTransactionResponses: [],
        memberBanks: [],
        memberAccumulationResponses: []
    };

    vm.pageSizes = {
        data: [
            { Name: 5, Value: 5 },
            { Name: 10, Value: 10 },
            { Name: 20, Value: 20 },
            { Name: 50, Value: 50 },
            { Name: 100, Value: 100 },
            { Name: 500, Value: 500 },
            { Name: 1000, Value: 1000 }
        ]
    };

    vm.filterData = {
        TotalPages: 1,
        TotalRows: null,
        PageSize: 10,
        PageIndex: 1,
        SortColumn: 'ProcessDate',
        SortOrder: 'ASC',
        BankID: null,
        PbmVendorID: null,
        PbmTransactionTypeID: null,
        PbmStartDate: null,
        PbmEndDate: null,
        PbmPageSize: 10,
        PbmPageIndex: 1,
        StartDate: null,
        EndDate: null,
        UpdatedBy: null,
        IsDetailed: false,
        IncludePaperless: true, // Not used unless on correspondence page, but added for future possibility.
        HoldAmount: null,
        Note: null,
    };

    vm.newMemberBank = {
        MemberID: null,
        AccountNumber: null,
        BankID: null
    };

    vm.getMemberBanks = getMemberBanks;
    vm.addNewMemberBank = addNewMemberBank;
    vm.newMemberBankFields = newMemberBankFields;
    vm.cancelEditMemberBank = cancelEditMemberBank;

    vm.getHoldAmounts = getHoldAmounts;
    vm.addNewHoldAmount = addNewHoldAmount;
    vm.closeNewHoldAmount = closeNewHoldAmount;
    vm.clearHoldAmount = clearHoldAmount;

    vm.getMemberTransactions = getMemberTransactions;
    vm.cancelEditTransactionResponse = cancelEditTransactionResponse;
    vm.updateBankTransactionResponseType = updateBankTransactionResponseType;
    vm.clearBankSelection = clearBankSelection;

    vm.getMemberPbmAccumulations = getMemberPbmAccumulations;
    vm.clearPbmSelection = clearPbmSelection;
    vm.clearPbmTransactionSelection = clearPbmTransactionSelection;

    vm.isEdit = isEdit;
    vm.refresh = refresh;
    vm.refreshPbm = refreshPbm;
    vm.isBankAccountExternallyCreated = isBankAccountExternallyCreated;
    vm.saveDefaultBank = saveDefaultBank;
    vm.banksToAdd = banksToAdd;
    vm.isDefaultDisabled = isDefaultDisabled;
    const banksWhereAccountsAreExternallyCreated = [bankEnum.Pnc];

    init();
    function init() {
        if (!memberID) return;

        vm.isInFinanceRole = accountService.isInRole('CareGuardFinance');
        vm.isInPaymentModificationRole = accountService.isInRole('Add-Edit-Delete-MemberPayment');
        vm.isInHoldModificationRole = accountService.isInRole('Add-Clear-MemberHoldAmount');

        vm.currentDate = moment(new Date).format('MM-DD-YYYY');

        const req = {
            LookupCategories: [
                "paymentType",
                "transactionType",
                "stateCode",
                "addresstype"
            ]
        };

        const promises = [lookupService.getLookUpsBatch(req)];

        promises.push(
            memberService.getById(memberID),
            memberFlagsService.getMemberFlags(memberID),
            getHoldAmounts(memberID),
            bankingService.getBanks(),
            memberService.loadAddresses(memberID),
            memberService.getPbmVendors()
        );

        Promise.all(promises).then(([lookupData, { Data: memberInfo }, { Data: memberFlags }, holdAmounts, { data: banks }, { Data: memberAddresses }, { data: pbmVendors }]) => {
            for (let key in lookupData) {
                vm.lookupdata[key.toLowerCase()] = lookupData[key];
            }

            vm.member = memberInfo;
            vm.memberflags = memberFlags;
            vm.activeBanks = banks.filter(bank => bank.isActive);
            vm.allBanks = banks;
            vm.memberAddresses = memberAddresses;
            vm.allPbmVendors = pbmVendors;

            return getMemberBanks().then(banks => {
                setMemberBanks(banks);
            });
        }).then(_ => getMemberTransactions())
            .then(_ => getMemberPbmAccumulations());
    }

    function banksToAdd() {
        const existingBankIds = vm.memberBanks.map(bank => bank.bankId);
        return vm.activeBanks.filter(bank => !existingBankIds.includes(bank.id));
    }

    function getMemberBanks() {
        return $q.all([
            bankingService.getMemberBanks(vm.member.MemberID),
            getMemberAccountCreationRequests()
        ]).then(([{ data: { bankAccounts: memberBanks }}, newBankAccountRequests]) => {
            const pendingMemberBankAccountRequests = filterNewBankAccountRequests(newBankAccountRequests);
            const pendingMemberBanks = buildPendingMemberBanks(pendingMemberBankAccountRequests);
            const promise = buildActiveMemberBanksAssignment(memberBanks);
            return $q.all([
                $q.when(pendingMemberBanks),
                promise
            ]);
        }).then(([pendingMemberBanks, activeMemberBanks]) => {
            return pendingMemberBanks.concat(activeMemberBanks);
        });
    }

    function filterNewBankAccountRequests(newBankAccountRequests) {
        return newBankAccountRequests
            .map(req => req.data)
            .filter(req => req.length)
            .flat()
            .filter(req => req.memberId === vm.member.MemberID);
    }

    function getMemberAccountCreationRequests() {
        const bankAgnosticBanks = vm.activeBanks.filter(bank => !isBankAccountExternallyCreated(bank.id));
        return $q.all(bankAgnosticBanks.map(bank => bankingService.getBankCreationRequests(bank.id, 'new')));
    }

    function buildPendingMemberBanks(pendingAccounts) {
        return pendingAccounts.map(memberBank => {
            let account = {};
            account.bankId = memberBank.bank;
            account.memberId = memberBank.memberId;
            account.bankName = vm.activeBanks.find(bank => bank.id == memberBank.bank).name;
            account.accountNumber = 'Pending';
            account.balance = '0.00';
            account.status = 'Pending';
            account.isDefault = false;
            return account;
        });
    }

    function buildActiveMemberBanksAssignment(memberBanks) {
        var promises = memberBanks?.map(memberBank => {
            return $q.all([
                getBankAccountNumber(memberBank),
                getBankAccountBalance(memberBank)
            ]).then(([accountNumber, balance = 0]) => {
                let account = {};
                account.bankId = memberBank.bank;
                account.memberId = vm.member.MemberID;
                account.bankName = vm.allBanks.find(bank => bank.id == memberBank.bank).name;
                account.accountNumber = accountNumber;
                account.accountKey = memberBank.accountKey;
                account.balance = balance;
                account.status = memberBank.status;
                account.isDefault = memberBank.isDefault;
                return account;
            });
        });

        return $q.all(promises);
    }

    function getBankAccountBalance(memberBank) {
        if (memberBank.status.toLowerCase() !== 'open') {
            return $q.when(memberBank.status);
        }

        return bankingService.getBankAccountBalanceForMember(memberBank.bank, vm.member.MemberID).then(({ data: { bankAccountBalances }}) => {
            return bankAccountBalances ? bankAccountBalances[0].balance : 0;
        });
    }

    function getBankAccountNumber(memberBank) {
        if (memberBank.status.toLowerCase() !== 'open') {
            return $q.when(memberBank.status);
        }

        return $q.when(memberBank.accountKey);
    }

    function isBankAccountExternallyCreated(bankId) {
        return banksWhereAccountsAreExternallyCreated.includes(Number(bankId));
    }

    function isExternalAccountCreationDataValid() {
        return duplicateAccountCheck().then(dupeAccount => {
            if (dupeAccount) {
                $toastr.show('Another account with the same account number already exists in this bank.', 'warning');
                return false;
            }

            if (!vm.newMemberBank.AccountNumber) {
                $toastr.show('No Account Number detected!', 'warning');
                return false;
            }

            if (vm.newMemberBank.AccountNumber && vm.newMemberBank.AccountNumber.length < 10
                && Number(vm.newMemberBank.BankID) === bankEnum.Pnc) {
                $toastr.show('If PNC account number has less than 10 digits, add "0\'s" before it to complete the 10 digits.', 'warning');
                return false;
            }
            return true;
        });
    }

    function isWebsterAccountCreationDataValid() {
        let errors = [];
        const memberPhysicalAddress = vm.memberAddresses.filter(addressTypeId => addressTypeId.AddressTypeId == addressType.Physical);

        return validateMemberSSN().then(isValidSSN => {
            if (!isValidSSN) {
                errors.push(`Member's SSN is invalid.`);
            }

            if (!vm.member.FirstName) {
                errors.push(`Member First Name is required.`);
            }

            if (!vm.member.LastName) {
                errors.push(`Member Last Name is required.`);
            }

            if (!vm.member.MemberNumber) {
                errors.push(`Member Number is required.`);
            }

            if (memberPhysicalAddress?.length > 0) {
                if (!memberPhysicalAddress[0].Address1 || !memberPhysicalAddress[0].City || !memberPhysicalAddress[0].State || !memberPhysicalAddress[0].Zip || memberPhysicalAddress[0].Address1.match(/\b[Pp]\.?[Oo]\.? ?[Bb]ox\b/)) {
                    errors.push(`Member's physical address is invalid.`);
                }
            } else {
                errors.push(`Physical Address is not present for this member". Please add physical Address.`);
            }

            if (errors.length) {
                $toastr.show('Member is missing the following information: <br/> ' + errors.join("<br/>") + ' <br/> A Webster account cannot be created until this is resolved.', 'warning');
                return;
            }
            return true;
        });
    }

    function addNewMemberBank() {
        if (!vm.newMemberBank.BankID > 0) {
            $toastr.show('No Bank is selected!', 'warning');
            return;
        }

        if (isBankAccountExternallyCreated(vm.newMemberBank.BankID)) {
            isExternalAccountCreationDataValid().then(validData => {
                if (!validData)
                    return;

                const request = {
                    MemberId: vm.member.MemberID,
                    BankAccount: {
                        Bank: Number(vm.newMemberBank.BankID),
                        AccountKey: vm.newMemberBank.AccountNumber,
                        Status: 'Open'
                    }
                }

                bankingService.addMemberBankAccount(request).then(() => {
                    resetNewMemberBankVariables();
                    getMemberBanks().then(banks => setMemberBanks(banks));
                })
            });

        } else {
            isWebsterAccountCreationDataValid().then((isValidData) => {
                if (isValidData) {
                    const request = {
                        MemberId: vm.member.MemberID,
                        Bank: Number(vm.newMemberBank.BankID)
                    }

                    bankingService.addMemberBankAccountCreationRequests([request]).then(() => {
                        resetNewMemberBankVariables();
                        getMemberBanks().then(banks => setMemberBanks(banks));
                    });
                }
            });
        }
    }

    function resetNewMemberBankVariables() {
        vm.isNewMemberBank = false;
        vm.newMemberBank.BankID = null;
        vm.newMemberBank.AccountNumber = null;
    }

    function setMemberBanks(banks) {
        vm.memberBanks = banks;
        angular.copy(vm.memberBanks, dataReferences.memberBanks);
    }

    function isEdit(object) {
        //this will fix the date format when trying to edit fee payments
        if (object.PaymentDate) {
            object.PaymentDate = object.PaymentDate.substring(0, object.PaymentDate.indexOf('T'));
        }

        if (object.isEdit) {
            object.isEdit = false;
        } else {
            object.isEdit = true;
        }
    }

    function cancelEditMemberBank(bank) {
        isEdit(bank);
        angular.copy(dataReferences.memberBanks, vm.memberBanks);
    }

    function saveDefaultBank(bank) {
        if (!bank.isDefault && bank.RequestedAccountStatus != "Request Account Closure") {
            $toastr.show('Please select at least one default bank in order to remove current default.', 'warning');
            return;
        }
        if (bank.balance != 0 && bank.RequestedAccountStatus === "Request Account Closure") {
            $toastr.show('Can request account closure only when the bank account has 0 balance.', 'warning');
            return;
        }

        isEdit(bank);

        if (bank.RequestedAccountStatus === 'Request Account Closure') {
            addMemberBankAccountClosureRequest(bank).then(refreshBanks);
        } else {
            bankingService.setDefaultBank(bank.bankId, vm.member.MemberID).then(refreshBanks);
        }
    }

    function addMemberBankAccountClosureRequest(bank) {
        if (bank.bankId === bankEnum.Pnc) {
            return bankingService.updateBankAccountStatus(bank.bankId, vm.member.MemberID, '"Closed"');
        }

        const requests = {
            MemberId: vm.member.MemberID,
            Bank: Number(bank.bankId)
        }

        return bankingService.addMemberBankAccountClosureRequest([requests])
            .then(() => bankingService.updateBankAccountStatus(bank.bankId, vm.member.MemberID, '"Pending Closure"'));
    }

    function refreshBanks() {
        return getMemberBanks().then(banks => setMemberBanks(banks));
    }

    function sortHoldAmounts(data) {
        return data.sort(function (a, b) {
            if (!b.ClearDate != !a.ClearDate) {
                return !b.ClearDate - !a.ClearDate;
            } else {
                return Date.parse(a.HoldDate) - Date.parse(b.HoldDate);
            }
        });
    }

    function getHoldAmounts(memberID) {
        return memberService.getMemberHoldsByMemberId(memberID || vm.member.MemberID).then(({ data: holds }) => {
            return vm.holdAmounts = sortHoldAmounts(holds);
        });
    }

    function addNewHoldAmount() {
        if (vm.newHoldAmount.Amount <= 0 || !vm.newHoldAmount.Amount) {
            $toastr.show('Invalid Amount!', 'warning');
            return;
        }

        if (!vm.newHoldAmount.Note) {
            vm.newHoldAmount.Note = null;
        }

        const request = {
            MemberId: vm.member.MemberID,
            Amount: vm.newHoldAmount.Amount,
            Note: vm.newHoldAmount.Note
        };

        return $q.all([
            memberService.addMemberHold(vm.member.MemberID, request),
            $q.when(memberService.getMember())
        ]).then(([{ data: newHold }, memberInfo]) => {
            vm.holdAmounts.push(newHold);
            vm.newHoldAmount = {};
            vm.isNewHoldAmount = false;
            return $q.when(updateLeftPanelWithNewHoldAmount(memberInfo, newHold));
        }).then(memberInfo => {
            return $q.when(memberService.setMember(memberInfo));
        });
    }

    function updateLeftPanelWithNewHoldAmount(memberInfo, newHold) {
        //this is needed until member page is fully using apis and models are consistent
        memberInfo.BalanceAmount = (memberInfo.currentBalances?.totalAvailableBalance ?? memberInfo.BalanceAmount) - newHold.amount;
        memberInfo.HoldAmount = (memberInfo.currentBalances?.holdAmount ?? memberInfo.HoldAmount) + newHold.amount;

        if (!memberInfo.currentBalances) {
            memberInfo.currentBalances = {};
            memberInfo.currentBalances.totalAvailableBalance = memberInfo.BalanceAmount;
            memberInfo.currentBalances.holdAmount = memberInfo.HoldAmount;
        } else {
            memberInfo.currentBalances.totalAvailableBalance = memberInfo.BalanceAmount;
            memberInfo.currentBalances.holdAmount = memberInfo.HoldAmount;
        }
        return memberInfo;
    }

    function closeNewHoldAmount() {
        vm.isNewHoldAmount = false;
        vm.newHoldAmount = {};
    }

    function clearHoldAmount(holdId) {
        return $q.all([
            memberService.clearMemberHold(holdId),
            $q.when(memberService.getMember())
        ]).then(([{ data: hold }, memberInfo]) => {
            let holdIndex = vm.holdAmounts.findIndex(hold => hold.id == holdId);
            vm.holdAmounts[holdIndex] = hold;
            return $q.when(updateLeftPanelWithClearedHoldAmount(memberInfo, hold));
        }).then(memberInfo => {
            return $q.when(memberService.setMember(memberInfo));
        });
    }

    function updateLeftPanelWithClearedHoldAmount(memberInfo, hold) {
        //this is needed until member page is fully using apis and models are consistent
        memberInfo.BalanceAmount = (memberInfo.currentBalances?.totalAvailableBalance ?? memberInfo.BalanceAmount) + hold.amount;
        memberInfo.HoldAmount = (memberInfo.currentBalances?.holdAmount ?? memberInfo.HoldAmount) - hold.amount;

        if (!memberInfo.currentBalances) {
            memberInfo.currentBalances = {};
            memberInfo.currentBalances.totalAvailableBalance = memberInfo.BalanceAmount;
            memberInfo.currentBalances.holdAmount = memberInfo.HoldAmount;
        } else {
            memberInfo.currentBalances.totalAvailableBalance = memberInfo.BalanceAmount;
            memberInfo.currentBalances.holdAmount = memberInfo.HoldAmount;
        }
        return memberInfo;
    }

    function clearBankSelection() {
        vm.filterData.BankID = null;
    }

    function clearPbmSelection() {
        vm.filterData.PbmVendorID = null;
    }

    function clearPbmTransactionSelection() {
        vm.filterData.PbmTransactionTypeID = null;
    }

    function getMemberTransactions() {
        return getBankTransactionResponses().then(({ data: transactionResponses }) => {
            vm.memberTransactionResponses = mapTransactionResponses(transactionResponses);
            angular.copy(vm.memberTransactionResponses, dataReferences.memberTransactionResponses);
            vm.filterData.TotalRows = transactionResponses.totalRows;
            vm.filterData.TotalPages = transactionResponses.totalPages;
        });
    }

    function getBankTransactionResponses() {
        return bankingService.getBankTransactionResponses(memberID, {
            OrderBy: 'id', StartDate: vm.filterData.StartDate, EndDate: vm.filterData.EndDate,
            BankId: vm.filterData.BankID, UpdatedBy: vm.filterData.UpdatedBy, PageSize: vm.filterData.PageSize, PageNumber: vm.filterData.PageIndex
        });
    }

    function mapTransactionResponses(transactionResponses) {
        return transactionResponses.items?.map(transaction => {
            transaction.bankName = vm.allBanks.find(bank => bank.id == transaction.bank).name;
            transaction.accountNumber = vm.memberBanks?.find(bank => bank?.bankId == transaction.bank)?.accountNumber;
            return transaction;
        });
    }

    function getMemberPbmAccumulations() {
        return getPbmAccumulatorHistory().then(({ data: accumulationResponses }) => {
            vm.memberAccumulationResponses = mapAccumulationResponses(accumulationResponses);
            angular.copy(vm.memberAccumulationResponses, dataReferences.memberAccumulationResponses);
            vm.filterData.PbmTotalRows = typeof accumulationResponses.totalRows !== 'undefined' ? accumulationResponses.totalRows : 1;
            vm.filterData.PbmTotalPages = accumulationResponses.totalPages;
        });
    }

    function getPbmAccumulatorHistory() {
        return memberService.getPbmAccumulatorHistory(memberID, {
            OrderBy: 'SentDate DESC', StartDate: vm.filterData.PbmStartDate, EndDate: vm.filterData.PbmEndDate,
            PbmVendorId: vm.filterData.PbmVendorID, PbmTransactionTypeId: vm.filterData.PbmTransactionTypeID, PageSize: vm.filterData.PbmPageSize, PageNumber: vm.filterData.PbmPageIndex
        });
    }

    function mapAccumulationResponses(accumulationResponses) {
        return accumulationResponses.items?.map(transaction => {
            transaction.pbmName = vm.allPbmVendors.find(pbm => pbm.id == transaction.pbmVendorId).vendor;
            transaction.pbmTransactionTypeName = transaction.transactionType == '0'? "Accumulation" : "Override";
            return transaction;
        });
    }

    function cancelEditTransactionResponse(transactionResponse) {
        isEdit(transactionResponse);
        angular.copy(dataReferences.memberTransactionResponses, vm.memberTransactionResponses);
    }

    function updateBankTransactionResponseType(transactionId) {
        const transactionToEdit = vm.memberTransactionResponses.find(transaction => transaction.id === transactionId);
        return bankingService.updateBankTransactionResponseType(transactionId, transactionToEdit.transactionType)
            .then(_ => {
                const transactionIndex = vm.memberTransactionResponses.findIndex(transaction => transaction.id === transactionId);
                vm.memberTransactionResponses[transactionIndex].transactionType = transactionToEdit.transactionType;
                isEdit(vm.memberTransactionResponses[transactionIndex]);
            });
    }

    function refresh(page) {
        if (page == null || (page > 0 && page <= vm.filterData.TotalPages)) {
            vm.filterData.PageIndex = (!vm.filterData.TotalRows || !page) ? 1 : page;
            return getMemberTransactions();
        }
    }

    function refreshPbm(page) {
        if (page == null || (page > 0 && page <= vm.filterData.PbmTotalPages)) {
            vm.filterData.PbmPageIndex = (!vm.filterData.PbmTotalRows || !page) ? 1 : page;
            return getMemberPbmAccumulations();
        }
    }

    function duplicateAccountCheck() {
        if (vm.newMemberBank.AccountNumber && vm.newMemberBank.BankID) {
            return memberService.getByAccountNumber(vm.newMemberBank.AccountNumber, vm.newMemberBank.BankID).then(function (result) {
                if (result.Data?.AccountStatus === 'Open') {
                    return true;
                }
            });
        }
        return $q.when(false);
    }

    function newMemberBankFields() {
        vm.isNewMemberBank = true;
    }

    function isDefaultDisabled(bank) {
        return !(vm.isInFinanceRole && bank.isEdit) || bank.status === 'Closed'
    }

    function validateMemberSSN() {
        return memberService.isValidMemberSSN(vm.member.SSN).then(({ data: result }) => result);
    }
}