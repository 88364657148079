
angular
    .module('CareGuard')
    .factory('documentService', documentService);

documentService.$inject = ['$http', 'fileService', 'documentTypeId'];

function documentService($http, fileService, documentTypeId) {
    // External API calls
    const baseRoute = `documents`;

    function getMemberDocumentFullPath({ memberNumber, filename }) {
        return `${memberNumber}/${filename}`;
    }

    function getDocumentById(id) {
        return $http.get(`${apiUrl}/${baseRoute}/${id}`);
    }

    function deleteDocumentDataById(id) {
        return $http.delete(`${apiUrl}/${baseRoute}/${id}`);
    }

    function getDocumentsByClaimIds(filters) {
        return $http.get(`${apiUrl}/${baseRoute}/claims`, { params: filters });
    }

    function getDocumentsByClaimIdsByBulk(filters) {
        return $http.post(`${apiUrl}/${baseRoute}/claims/bulk`, filters);
    }

    const getMemberDocuments = (filters) => {
        return $http.get(`${apiUrl}/${baseRoute}/members/search`, { params: filters });
    };

    function addDocument(document) {
        return $http.post(`${apiUrl}/${baseRoute}`, document);
    }

    function updateDocument(document) {
        return $http.patch(`${apiUrl}/${baseRoute}`, document);
    }

    function deleteDocumentById(id) {
        return $http.delete(`${apiUrl}/${baseRoute}/${id}/soft`);
    }

    function getFilesByClaimId(claimId) {
        return $http.get(`${apiUrl}/${baseRoute}/claims/files/${claimId}`);
    }

    function getFileByFileProcessingId(fileProcessingId) {
        return $http.get(`${apiUrl}/${baseRoute}/fileProcessing/${fileProcessingId}`);
    }

    function downloadMemberAzureBlobDocument({ memberNumber, filename }) {
        const filepath = getMemberDocumentFullPath({ memberNumber, filename });

        return $http.get(`${apiUrl}/${baseRoute}/blob/download`, { params: { filepath }, responseType: `arraybuffer` });
    }

    function uploadMemberAzureBlobDocument({ memberNumber, file, filename }) {
        const formFile = fileService.createFileWithName(file, filename);

        return $http.post(`${apiUrl}/${baseRoute}/blob`, formFile,
            { params: { prependedFilePath: memberNumber }, headers: { 'Content-Type': undefined } });
    }

    function doesMemberAzureBlobDocumentExist({ memberNumber, filename }) {
        const filepath = getMemberDocumentFullPath({ memberNumber, filename });

        return $http.get(`${apiUrl}/${baseRoute}/blob/exists`, { params: { filepath } });
    }

    function deleteMemberAzureBlobDocument({ memberNumber, filename }) {
        const filepath = getMemberDocumentFullPath({ memberNumber, filename });

        return $http.delete(`${apiUrl}/${baseRoute}/blob`, { params: { filepath } });
    }

    function exportClaimsToExcel(data) {
        return $http.post(`${apiUrl}/${baseRoute}/exports/claims`, data, { responseType: `arraybuffer` });
    }

    // CH C# controller calls
    const baseUrl = '/api/documents';

    const searchMembers = (membernumber, lastname, firstname, ssnlastfour, dateofbirth, accountstatusid, settlementmonth, fundtype, pageindex, pagesize, sortcolumn, sortorder) => {
        return $http.get(`${baseUrl}/searchMembers/${membernumber}/${lastname}/${firstname}/${ssnlastfour}/${dateofbirth}/${accountstatusid}/${settlementmonth}/${fundtype}/${pageindex}/${pagesize}/${sortcolumn}/${sortorder}/`);
    }

    const exportMemberStatements = (fileName, extension) => {
        return $http.get(`${baseUrl}/exportMemberStatements/${fileName}/${extension}/`);
    }

    const uploadFile = (file, membernumber) => {
        return $http({
            url: `${baseUrl}/uploadFile/${membernumber}/`,
            method: 'POST',
            data: file,
            withCredentials: true,
            headers: { 'Content-Type': undefined },
            transformRequest: angular.identity
        });
    }

    const getDocumentFile = (membernumber, filename, extension) => {
        return $http.get(`${baseUrl}/getDocumentFile/${membernumber}/${filename}/${extension}/`);
    }

    const fillClaimDocumentData = function (claimModel) {
        return getDocumentsByClaimIds({ ids: [claimModel.id] }).then(({ data: documents }) => {
            return getClaimEors(claimModel.id).then(({ data: eors }) => {
                assignDocuments(claimModel, documents, eors);
            });
        });
    }

    const assignDocuments = function (claimModel, documents, eors) {
        var currentEor = null;
        var allEors = [];
        if (eors) {
            eors.forEach(e => {
                var eor = angular.copy(e);
                eor.fileName = documents.find(doc => doc.id === e.documentId)?.physicalPath;
                
                if (e.isCurrent) {
                    currentEor = eor;
                }

                allEors.push(eor);
            });
        }

        var eorPath = null;
        if (currentEor) {
            eorPath = currentEor.fileName;
        }

        const { physicalPath: eorStatePath } = documents.find(document => document.documentTypeID === documentTypeId.EORState) ?? {};
        const { physicalPath: billPath } = documents.find(document => ![documentTypeId.EOR, documentTypeId.EORState].includes(document.documentTypeID)) ?? {};

        claimModel.files = {
            allEors: allEors,
            currentEor: currentEor,
            eorFile: eorPath,
            eorStateFile: eorStatePath,
            billFile: billPath,
        };

        claimModel.documents = documents;
    }

    const getClaimEors = function (claimId) {
        return getClaimEorsByClaimIds([claimId]);
    }

    const getClaimEorsByClaimIds = function (claimIds) {
        return $http.post(`${apiUrl}/${baseRoute}/claims/eors/bulk`, claimIds);
    }

    const resetCurrentClaimEorByClaimIds = function (claimIds) {
        return $http.patch(`${apiUrl}/${baseRoute}/claims/eors/reset`, claimIds);
    }

    return {
        // External API calls
        getDocumentById,
        deleteDocumentDataById,
        getDocumentsByClaimIds,
        getDocumentsByClaimIdsByBulk,
        addDocument,
        getFilesByClaimId,
        downloadMemberAzureBlobDocument,
        uploadMemberAzureBlobDocument,
        doesMemberAzureBlobDocumentExist,
        deleteMemberAzureBlobDocument,
        exportClaimsToExcel,
        updateDocument,
        deleteDocumentById,
        getMemberDocuments,
        getClaimEorsByClaimIds,
        resetCurrentClaimEorByClaimIds,
        getFileByFileProcessingId,

        // CH C# controller calls
        searchMembers,
        exportMemberStatements,
        uploadFile,
        getDocumentFile,
        fillClaimDocumentData
    };
}