import lineNumberTemplate from './html/lineNumberTemplate.html';
import flagTemplate from './html/flagTemplate.html';
import dateTemplate from './html/dateTemplate.html';
import modifier1Template from './html/modifierTemplate.html';
import modifier2Template from './html/modifier2Template.html';
import modifier3Template from './html/modifier3Template.html';
import modifier4Template from './html/modifier4Template.html';
import revenueTemplate from './html/revenueTemplate.html';
import procedureTemplate from './html/procedureTemplate.html';
import unitTemplate from './html/unitTemplate.html';
import ndcTemplate from './html/ndcTemplate.html';
import ndcQuantityTemplate from './html/ndcQuantityTemplate.html';
import billTemplate from './html/billTemplate.html';
import billReviewTemplate from './html/billReviewTemplate.html';
import networkTemplate from './html/networkTemplate.html';
import outOfNetworkTemplate from './html/outOfNetworkTemplate.html'
import payableTemplate from './html/payableTemplate.html';
import feeTemplate from './html/feeTemplate.html';
import claimDetailStatusTemplate from './html/explanationTemplate.html';
import deleteLineItemTemplate from './html/deleteLineitemTemplate.html';
import internalReductionTemplate from './html/internalReductionTemplate.html';
import diagnosisCodePointersTemplate from './html/diagnosisCodePointersTemplate.html';
import placeOfServiceTemplate from './html/placeOfServiceTemplate.html';

export const lineItemsColumnDefsGrid = [
    {
        field: 'lineNumber',
        displayName: 'Line Number',
        headerCellClass: 'grid-component_header showExtendedContent',
        headerTooltip: false,
        width: '4%',
        enableCellEdit: false,
        allowCellFocus: false,
        cellTemplate: lineNumberTemplate
    },
    {
        field: 'flag',
        displayName: 'Flag',
        headerCellClass: 'grid-component_header showExtendedContent',
        headerTooltip: false,
        visible: false,
        width: '3%',
        allowCellFocus: false,
        cellTemplate: flagTemplate
    },
    {
        field: 'serviceDate',
        displayName: 'From Date * To Date',
        headerCellClass: 'grid-component_header fromDate',
        cellClass: 'lineItems-date-input',
        headerTooltip: false,
        enableCellEdit: false,
        allowCellFocus: false,
        cellFilter: "date:\'MM/dd/yyyy\'",
        width: '7%',
        cellTemplate: dateTemplate,
        required: true
    },
    {
        field: 'placeOfService',
        displayName: 'POS',
        headerCellClass: 'grid-component_header',
        cellClass: 'overflow-visible',
        headerTooltip: false,
        required: false,
        enableCellEdit: false,
        allowCellFocus: false,
        width: '3%',
        conditionallyRequired: true,
        cellTemplate: placeOfServiceTemplate
    },
    {
        field: 'modifier1ID',
        displayName: 'M1',
        headerCellClass: 'grid-component_header',
        headerTooltip: 'Modifier 1',
        cellClass: 'overflow-visible',
        enableCellEdit: false,
        allowCellFocus: false,
        width: '3%',
        cellTemplate: modifier1Template,
        required: false
    },
    {
        field: 'modifier2ID',
        displayName: 'M2',
        headerCellClass: 'grid-component_header',
        headerTooltip: 'Modifier 2',
        cellClass: 'overflow-visible',
        enableCellEdit: false,
        allowCellFocus: false,
        width: '3%',
        cellTemplate: modifier2Template,
        required: false
    },
    {
        field: 'modifier3ID',
        displayName: 'M3',
        headerCellClass: 'grid-component_header',
        headerTooltip: 'Modifier 3',
        cellClass: 'overflow-visible',
        enableCellEdit: false,
        allowCellFocus: false,
        width: '3%',
        cellTemplate: modifier3Template,
        required: false
    },
    {
        field: 'modifier4ID',
        displayName: 'M4',
        headerCellClass: 'grid-component_header',
        headerTooltip: 'Modifier 4',
        cellClass: 'overflow-visible',
        enableCellEdit: false,
        allowCellFocus: false,
        width: '3%',
        cellTemplate: modifier4Template,
        required: false
    },
    {
        field: 'revenueServiceCode',
        displayName: 'Revenue *',
        headerCellClass: 'grid-component_header',
        cellClass: 'overflow-visible',
        headerTooltip: false,
        required: false,
        enableCellEdit: false,
        allowCellFocus: false,
        conditionallyRequired: true,
        width: '7%',
        cellTemplate: revenueTemplate
    },
    {
        field: 'procedureServiceCode',
        displayName: 'Procedure *',
        headerCellClass: 'grid-component_header',
        cellClass: 'overflow-visible',
        headerTooltip: false,
        required: false,
        enableCellEdit: false,
        allowCellFocus: false,
        width: '7%',
        conditionallyRequired: true,
        cellTemplate: procedureTemplate
    },
    {
        field: 'units',
        displayName: 'Units*',
        headerCellClass: 'grid-component_header',
        width: '5%',
        required: true,
        editableCellTemplate: unitTemplate
    },
    {
        field: 'ndcServiceCode',
        displayName: 'NDC *',
        headerCellClass: 'grid-component_header',
        cellClass: 'overflow-visible',
        enableCellEdit: false,
        allowCellFocus: false,
        required: false,
        width: '7%',
        conditionallyRequired: true,
        cellTemplate: ndcTemplate
    },
    {
        field: 'ndcQuantity',
        displayName: 'NDC Quantity',
        headerCellClass: 'grid-component_header',
        cellClass: 'overflow-visible',
        enableCellEdit: false,
        allowCellFocus: false,
        required: false,
        width: '7%',
        ndcConditionallyRequired: true,
        cellTemplate: ndcQuantityTemplate
    },
    {
        field: 'diagnosisCodePointers',
        displayName: 'Dx Code Pointers',
        headerCellClass: 'grid-component_header',
        required: false,
        enableCellEdit: false,
        allowCellFocus: false,
        width: '7%',
        cellTemplate: diagnosisCodePointersTemplate
    },
    {
        field: 'billedAmount',
        displayName: 'Billed',
        headerCellClass: 'grid-component_header',
        cellFilter: "currency",
        required: false,
        editableCellTemplate: billTemplate
    },
    {
        field: 'billReviewAmount',
        displayName: 'Bill Review',
        headerCellClass: 'grid-component_header',
        cellFilter: "currency",
        required: false,
        editableCellTemplate: billReviewTemplate
    },
    {
        field: 'networkAmount',
        displayName: 'Network',
        headerCellClass: 'grid-component_header',
        cellFilter: "currency",
        required: false,
        editableCellTemplate: networkTemplate
    },
    {
        field: 'outOfNetworkAmount',
        displayName: 'Audit',
        headerCellClass: 'grid-component_header',
        cellFilter: "currency",
        required: false,
        editableCellTemplate: outOfNetworkTemplate
    },
    {
        field: 'internalReductionAmount',
        displayName: 'Internal',
        headerCellClass: 'grid-component_header',
        cellFilter: "currency",
        required: false,
        editableCellTemplate: internalReductionTemplate
    },
    {
        field: 'payableAmount',
        displayName: 'Payable',
        headerCellClass: 'grid-component_header',
        cellFilter: "currency",
        required: false,
        editableCellTemplate: payableTemplate
    },
    {
        field: 'feeAmount',
        displayName: 'Fee',
        headerCellClass: 'grid-component_header',
        cellFilter: "currency",
        width: '4%',
        required: false,
        editableCellTemplate: feeTemplate
    },
    {
        field: 'explanation',
        displayName: 'Explanation',
        enableCellEdit: false,
        allowCellFocus: false,
        headerCellClass: 'grid-component_header',
        required: false,
        width: '12%',
        cellTemplate: claimDetailStatusTemplate
    },
    {
        field: 'Actions',
        displayName: '',
        width: '2%',
        enableCellEdit: false,
        headerCellClass: 'grid-component_header',
        cellClass: 'grid-component_actions',
        required: false,
        cellTemplate: deleteLineItemTemplate
    }
];