angular
	.module('CareGuard')
	.factory('codeService', codeService);

codeService.$inject = ['$http'];

function codeService($http) {
	const baseRoute = `codes`;

	function search(query) {
		return $http.get(`${apiUrl}/${baseRoute}/search`, { params: query });
    }

	function searchAllCodes({ code, codeTypes, description, pageNumber, pageSize }) {
		return search({ code, codeTypes, description, pageNumber, pageSize });
    }

	function searchCodes({ code, pageNumber, pageSize }) {
		return search({ code, isModifier: false, pageNumber, pageSize });
	}

	function searchProcedureCodes({ code, pageNumber, pageSize }) {
		return search({ code, codeTypes: ['CPT', 'HCPCS'], isModifier: false, pageNumber, pageSize });
	}

	function searchRevenueCodes({ code, pageNumber, pageSize }) {
		return search({ code, codeTypes: ['REVENUE'], isModifier: false, pageNumber, pageSize });
	}

	function searchNDCCodes({ code, pageNumber, pageSize }) {
		return search({ code, codeTypes: ['NDC'], isModifier: false, pageNumber, pageSize });
    }

	function searchModifiers({ code, pageNumber, pageSize }) {
		return search({ code, isModifier: true, pageNumber, pageSize });
	}

	function searchDiagnosisCodes({ code, pageNumber, pageSize }) {
		return search({ code, codeTypes: ['ICD10'], isModifier: false, pageNumber, pageSize });
	}

	function getServiceCodeById(id) {
		return $http.get(`${apiUrl}/${baseRoute}/${id}`);
	}

	function updateCode(code) {
		return $http.put(`${apiUrl}/${baseRoute}`, code);
	}

	function updateCodeMemberProfile(code) {
		return $http.put(`${apiUrl}/${baseRoute}/members/mappings`, code);
	}

	function getDiagnosisCodesByClaimId(claimId) {
		return $http.get(`${apiUrl}/${baseRoute}/claims/${claimId}`);
    }

	function getQuantityMeasurements() {
		return $http.get(`${apiUrl}/${baseRoute}/measurements`);
	}

	function addDiagnosisCodesToClaim(request) {
		return $http.post(`${apiUrl}/${baseRoute}/claims`, request);
	}

	function getAllCodeTypes() {
		return $http.get(`${apiUrl}/${baseRoute}/types`);
	}

	function addCode(request) {
		return $http.post(`${apiUrl}/${baseRoute}`, request);
	}

	function getMemberCodeById(id) {
		return $http.get(`${apiUrl}/${baseRoute}/members/mappings/${id}`);
    }

	function getMemberCodes(memberId) {
		return $http.get(`${apiUrl}/${baseRoute}/members/${memberId}/mappings`);
	}

	function addCodeToMember(request) {
		return $http.post(`${apiUrl}/${baseRoute}/members/mappings`, request);
	}

	function deleteMemberCodeById(id) {
		return $http.delete(`${apiUrl}/${baseRoute}/members/mappings/${id}`);
    }

	return {
		searchAllCodes,
		searchCodes,
		searchProcedureCodes,
		searchRevenueCodes,
		searchNDCCodes,
		searchModifiers,
		searchDiagnosisCodes,
		getServiceCodeById,
		updateCode,
		updateCodeMemberProfile,
		getDiagnosisCodesByClaimId,
		getQuantityMeasurements,
		addDiagnosisCodesToClaim,
		getAllCodeTypes,
		addCode,
		getMemberCodeById,
		getMemberCodes,
		addCodeToMember,
		deleteMemberCodeById,
	};
}